import React from 'react';
import './cta.css';
import resume from '../../assets/Resume.pdf';

const CTA = () => {
  return (
    <div className="kdock__cta">
      <div className="kdock__cta-content">
        <p>Download my resume here</p>
        <h3>Download my resume here if you are interested in working together</h3>
      </div>
      <div className="kdock__cta-btn">
        <button type="button"><a href= {resume}>Download</a></button>
      </div>
    </div>
  )
}

export default CTA